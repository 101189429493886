@import url("https://fonts.googleapis.com/css2?family=Aguafina+Script&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;800&display=swap');

* {
  box-sizing: border-box;
}
body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.preload {
  display: hidden;
  height: 1px;
  width: 1px;
  position: absolute;
}
